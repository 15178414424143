<script>
import { addClass } from '@/helpers/text-editor-helper.js';


export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage = this.block.fields.Media?.templateId === 'Image'
      && this.$toLitiumMediaUrl(this.block.fields.Media?.id, { maxWidth: 100 });

    return {
      highResolutionImage: null,
      imgUrl: lowResolutionImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    if (!this.isImage) {
      return;
    }

    const media = this.block.fields.Media;
    const container = this.$refs.mediaContainer;
    const mediaAspectRatio = media.width / media.height;
    const containerAspectRatio = container.clientWidth / container.clientHeight;

    this.highResolutionImage = this.$toLitiumMediaUrl(this.block.fields.Media?.id,
      {
        ...(mediaAspectRatio < containerAspectRatio
          ? { maxWidth: container.clientWidth }
          : { maxHeight: container.clientHeight }),
      });

    this.$nextTick(() => {
      const img = new Image();

      img.onload = () => {
        this.imgUrl = this.highResolutionImage;
        this.isImageDownloaded = true;
      };

      img.src = this.highResolutionImage;
    });
  },
  computed: {
    desktopMediaAlignmentStyle() {
      const val = this.block.fields.DesktopMediaAlignment?.value || 'left';

      return val === 'left' ? 'row' : 'row-reverse';
    },
    textContent() {
      return addClass(this.block.fields.TextContent, 'text-with-media');
    },
    isImage() {
      return this.block.fields.Media?.templateId === 'Image';
    },
    isVideo() {
      return this.block.fields.Media?.templateId === 'Movie';
    },
    videoUrl() {
      return this.$toLitiumMediaUrl(this.block.fields.Media?.id);
    },
  },
};
</script>

<template>
<div
  class="text-with-media__container"
  :style="{'flex-direction' : desktopMediaAlignmentStyle}"
>
    <div class="text-with-media__media-container" ref="mediaContainer">
      <div class="text-with-media__media-spacer"></div>
      <img
        v-if="isImage"
        :class="['text-with-media__media-content',
        {'text-with-media__image-loading' : !isImageDownloaded}]"
        :src="imgUrl"
      >
      <video
        v-if="isVideo"
        muted
        autoplay
        loop
        playsinline
        class="text-with-media__media-content"
        :src="videoUrl"
      >
      </video>
    </div>
    <div class="text-with-media__text-container text-editor-content" v-html="textContent"></div>
  </div>
</template>

<style>
  .text-with-media__container {
    max-width: 1440px;
    padding: 10px;
  }

  .text-with-media__media-container {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .text-with-media__media-spacer {
    width: 100%;
    padding-top: 66.666%;

  }

  .text-with-media__media-content {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
  }

  .text-with-media__image-loading {
    filter: blur(5px);
  }

  h1.text-with-media__text-element,
  h2.text-with-media__text-element {
    margin-bottom: 6px;
  }

  p.paragraph-bold {
    margin: 6px 0 25px;
    font-weight: bold;
    color: var(--color-neutrals-60);
  }

  p.text-with-media__text-element {
    font-size: 16px;
    line-height: 24px;
  }

  ul.text-with-media__text-element {
    margin: 25px 0 30px;
  }

  ul.text-with-media__text-element > li {
    margin-top: 15px;
  }

  @media (--tabletAndDesktop) {
    p.paragraph-bold {
      margin-bottom: 30px;
    }

    ul.text-with-media__text-element {
      margin-top: 15px;
    }
  }

  @media (--desktop) {
    .text-with-media__container {
      margin: auto;
      display: flex;
      justify-content: space-between;
      padding: 20px 95px;
    }

    .text-with-media__media-spacer {
      width: 100%;
      padding-top: 100%;
    }

    .text-with-media__media-container,
    .text-with-media__text-container {
      width: 565px;
      max-width: 50%;
      margin: 0 20px;
    }

  }
</style>
